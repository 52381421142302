import { template as template_a11115fb55b14cd2a901aaad8bbc326f } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_a11115fb55b14cd2a901aaad8bbc326f(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;

import { template as template_e8ee9670fb6c4983802239246702b53b } from "@ember/template-compiler";
const FKText = template_e8ee9670fb6c4983802239246702b53b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_8ff4cd1befc849fab3d918dcad7e49ca } from "@ember/template-compiler";
const WelcomeHeader = template_8ff4cd1befc849fab3d918dcad7e49ca(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

import { template as template_770145122c7840d0a9b3bdaefc3e5324 } from "@ember/template-compiler";
const FKLabel = template_770145122c7840d0a9b3bdaefc3e5324(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
